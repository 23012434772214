export default function PersonalBandList({bands, setPopup, setActiveBand}) {

  const handleClick = (band) => {
    setActiveBand(band)
    setPopup(true)
  }

  return (
    <div className="org-band-list">
      
      {bands.map((band) => (
        <li 
          key={band.id} 
          onClick={() => {handleClick(band)}}
          id='active'
        >
          <h1>{band['Event Name']}</h1>
          <h2>{band['date'].toDate().toDateString()}</h2>
          <h2>{band['Location']}</h2>
          <p>$ {band['Price']}</p>
        </li> 
      ))}
    </div>
  )
}