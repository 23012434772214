import {Link} from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { stack as Menu } from 'react-burger-menu'
import styled from "styled-components";

const StyledLink = styled(Link)`
    color: white;
    font-size: 1.2em;
    font-family: 'Raleway', Arial, sans-serif;
    margin-left: 10px;
    font-weight: 700;
    text-decoration: underline;
    margin-bottom: 20px;
`;

function Headbar(){
    const { logout } = useLogout()
    const { user } = useAuthContext()

    return (  
        <nav className="headbar">    
            <div className="menu">
                <Menu className="links">
                    {user && <StyledLink to="/userhome">Home</StyledLink>}
                    {!user && <StyledLink to="/">About</StyledLink>}
                    {!user && <StyledLink to="/signup">Sign Up</StyledLink>}
                    {!user && <StyledLink to="/login">Log In</StyledLink>}
                    {user && <StyledLink to="/create">Create BAND</StyledLink>}
                    {user && <StyledLink to= "/invite">Invite</StyledLink>}
                    {user && <StyledLink to= "/verify">Verify BANDs</StyledLink>}
                    {user && <StyledLink to= "/attendees">View Attendees</StyledLink>}
                    {/* {user && <StyledLink to="/search">Search Users</StyledLink>} */}
                    {user && <StyledLink to= "/edit">Edit BANDs</StyledLink>}                    
                    {/* {user && <StyledLink to= "/history">Inactive BANDs</StyledLink>} */}
                    {user && <button className="logout" onClick={logout}>Logout</button>}
                </Menu>
            </div>
            <div className="headlogo">
                {<Link className = "Logo" to="/">BAND</Link>}
            </div>
            <div className="rightcorner">
                {user && <p>{user.email}</p>}
            </div>
            
        </nav>
    );
}
 
export default Headbar;