import { initializeApp } from 'firebase/app'
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCvhxxjIOAp8R-0YoG4N2rWnNYccazY88U",
  authDomain: "bandticketing.com", //Live Version: bandticketing.com    //Test Version: bandticketing.firebaseapp.com
  projectId: "bandticketing",
  storageBucket: "bandticketing.appspot.com",
  messagingSenderId: "32132726225",
  appId: "1:32132726225:web:e129ae12da3f5251d5f4f0",
  measurementId: "G-4FMQTJ09TP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

//init firestore 
const db = getFirestore(app)

//init firebase auth
const auth = getAuth()
const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()

//init firebase cloud functions
const functions = getFunctions(app);

export { db, auth, googleProvider, facebookProvider, functions }