import bandGraphic from "../BAND.png";

export default function BandPopup({band}) {

  const handleClick = (band) => {
    // navigate('/band', {state: band})
    console.log("Bought")
  }

  return (
    <div className="band-popup">
        
        <div className="wrapper" style={{background: band.color}}>
          <img src={bandGraphic} alt="error" />
        </div>
        <h2>{band['Event Name']}</h2>
        <h2>{band['date'].toDate().toDateString()}</h2>
        <p>{band['Location']}</p>
        <p>$ {band['Price']}</p>
        <button
            className="regular"
            onClick={handleClick}
        >Buy</button>
    </div>
  )
}