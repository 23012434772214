//Component Imports
import { useState } from "react";
import Headbar from "../components/Headbar";
import AttendeeList from "../components/AttendeeList";

//Custom Hook Imports
import { useCollection } from "../hooks/useCollection";
import { useAuthContext } from "../hooks/useAuthContext";
import { db } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";

export default function ViewAttendees() {
    const { user } = useAuthContext()
    const temp = user ? user:{email:" "}

    const [ attendees, setAttendees ] = useState(null)
    const [ band, setBand ] = useState('')
    const {documents: bands} = useCollection(`users/${temp.email}/created`)

    const handleSelect = async (b) => {
        setBand(b)
        const bandRef = doc(db, `users/${temp.email}/created`, b)
        const docSnap = (await getDoc(bandRef)).data();
        setAttendees(docSnap.attendees)
    }

    return(
        <div className="attendeehome">
            <Headbar/>
            <div className="body">
                <h1>View Attendees</h1>
                <h2>Pick BAND</h2>
                {bands && 
                <select 
                    className="select-list"
                    value = {band}
                    onChange={(e)=>{
                        handleSelect(e.target.value)
                    }}
                >
                    <option value="">Select BAND</option>
                    {bands.map(band => (
                        <option key={band.id} value={band.id}>
                            {band['Event Name']}
                        </option>
                    ))}
                </select>}
                {attendees && <p>{attendees.length} Attendees</p> }
                {attendees &&  attendees.map((attendee) => (
                    <li 
                    key={attendee} 
                    id='active'
                    >
                    <p>{attendee}</p>
                    </li> 
                ))
                }
            </div>
        </div>
    )
    
}