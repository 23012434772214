import { useNavigate } from "react-router-dom";

export default function OrgList({orgs, query}) {
  const navigate = useNavigate()

  const handleClick = (org) => {
    navigate('/org', {state: org})
  }
  
  return (
    <div className="org-list">
      {orgs && orgs.filter((org) => {
        var name = org.email.split("@")[0];
        return query.toLowerCase() === '' ? org : name.toLowerCase().includes(query.toLowerCase())
      }).map((org) => (
        <li 
          key={org.email} 
          onClick={() => {handleClick(org)}}
          id='active'
        >
          <p>{org.email}</p>
        </li> 
      ))}
    </div>
  )
}